<template>
  <div>
    <base-template :istitle="false" :title="$t('cupones.wodekaquan')" @headerBack="headerBack">
      <template v-slot:content-top>
        <span class="clear-btn" @click="tipsShow = true" />
      </template>
      <div class="cupon-list">
        <van-radio-group v-model="radio" @change="changeHandle">
          <cuponItem v-for="(item, index) in dataList" :key="index" :isSelect="true" :money="item.TUZLhbtv"
            :time="item.IjjAhMsb" :title="item.title" :type="item.Umusango_Ibumba" :id="item.id"
            @click.native="handleOldRadio(item)"> </cuponItem>
        </van-radio-group>
      </div>
    </base-template>
    <van-popup v-model="tipsShow" round>
      <div class="popup">
        <div class="popup-box">
          <h3 class="tips-title">{{ $t('cupones.shiyongguize') }}</h3>
          <div class="tips-content">
            <p v-for="(item, i) in cuponTips" :key="i">{{ item }}</p>
          </div>
        </div>
        <img class="popup-close" @click="tipsShow = false" src="@/assets/images/coupon/popup-close.png" alt="">
      </div>

    </van-popup>
  </div>
</template>

<script>
import baseTemplate from '@/components/publicTemplate/components/baseTemplate.vue'
import cuponItem from '@/views/userCenter/cupones/components/cuponItem.vue'
import { ticketList } from '@/api/axios/index'
export default {
  components: { baseTemplate, cuponItem },
  props: {
    type: Number  //1还款券  2提现券
  },
  data() {
    return {
      tipsShow: false,//提示
      radio: '',
      oldRadio: '',
      dataList: [],
      cuponTips: []
    }
  },
  created() {
    this.getInitCupon()
    console.log(this.type, "优惠券类型")
  },

  methods: {
    handleOldRadio(item) {
      if (item.id == this.oldRadio) {
        this.oldRadio = ''
        this.radio = ''
      } else {
        this.oldRadio = item.id
      }
    },
    headerBack() {
      this.$emit('headerBack')
    },
    async getInitCupon() {
      console.log('aaaa');
      let { data } = await ticketList({ Umusango_Ibumba: 2 })
      console.log(data);
      if (this.type == 1) {
        this.dataList = data.KfHoYvar.voucher_ticket
      } else if (this.type == 2) {
        this.dataList = data.KfHoYvar.withdraw_ticket
      }
   
      // this.radio = this.dataList[0]?.id || ''
      // this.oldRadio = this.dataList[0]?.id || ''
      this.changeHandle(this.radio)
      this.cuponTips = data.yimbo
      this.$emit('interface', this.dataList)

    },
    changeHandle(val) {
      this.headerBack()
      this.$emit('change', val, this.dataList.find((item) => {
        return item.id == val
      }))
    },




  }
}
</script>
<style lang="scss" scoped>
.cupon-list {
  padding: 0 40px 40px 40px;
}

.clear-btn {
  width: 72px;
  height: 72px;
  position: absolute;
  right: 40px;
  top: 90px;
  background: url('~@/assets/images/coupon/tisp-icon.png') no-repeat center center;
  background-size: 100%;
}

.cupon-list {
  padding-top: 40px;
}

/deep/ .van-popup {
  overflow: inherit;
  top: 50% !important;
  left: 50% !important;
  right: inherit !important;
}

.popup {
  position: relative;

  .popup-close {
    position: absolute;
    width: 80px;
    height: 80px;
    bottom: -88px;
    left: 50%;
    margin-left: -40px;
  }

  .popup-box {
    padding: 40px;
    width: 670px;
    background: #FFFFFF;
    border-radius: 24px;

    .tips-title {
      font-size: 48px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #000000;
      line-height: 72px;
      text-align: center;
    }

    .tips-content {
      width: 606px;
      max-height: 746px;
      overflow-y: scroll;
      margin: 0 auto;
      padding: 32px;
      background: #F7F7F7;
      border-radius: 24px;
      line-height: 40px;
    }

  }
}
</style>
