<template>
  <van-popup v-model="visbile" round position="bottom" :style="{ height: '92%' }" :close-on-click-overlay='false'>
    <div @click="closeAction" class="detail-head">
      <img src="../../../assets/images/compoundLoan/back-icon.png" alt="">
      <p>{{ $t('loan.xiangqing') }}</p>
    </div>
    <div class="content-box">
      <div class="loan-info">
        <span>{{ $t('loan.jiekuanjine') }}</span>
        <span>PHP {{ getmoney(infoData.ukufumyaIfingi) }}</span>
      </div>
      <div class="loan-info">
        <span>{{ $t('loan.jiekuantianshu') }}</span>
        <span>{{ infoData.Ukulungisha_bwino }} {{ $t('loan.day') }}</span>
      </div>
      <div class="loan-info">
        <span>{{ $t('loan.firstloanday') }}</span>
        <span>{{ infoData.LoanTerm }} {{ $t('loan.day') }}</span>
      </div>
      <div class="plan-box">
        <p class="plan-title">{{ $t('loan.huankuanjihua') }}</p>
        <div class="plan-list">
          <ul>
            <li class="plan-item " :class="index == 0 ? 'plan-first-item' : ''"
              v-for="(item, index) in infoData.Ukubwekeshapo_ukwingila" :key="index">
              <p class="plan-item-top">
                <span>{{ item.ukufumyaIfingi }}</span>
                <span v-if="index == 0">1st</span>
                <span v-else>{{ index + 1 }}nd</span>
              </p>
              <p class="plan-item-bottom">{{ item.des }}</p>
              <span class="plan-item-data">{{ item.UkucinjaKwaku }}</span>
            </li>
            <!-- <li class="plan-item">
              <p class="plan-item-top">
                <span>3,000peso</span>
                <span>1st</span>
              </p>
              <p class="plan-item-bottom">Principal 1000+ Interest 98</p>
              <span class="plan-item-data">2022/12/02</span>
            </li> -->
          </ul>
          <div class="plan-footer">
            <img src="../../../assets/images/compoundLoan/exclamatory-icon.png" alt="">
            <p>{{ $t('loan.shouqiqiane') }}</p>
          </div>
        </div>
      </div>
      <div class="loan-info">
        <span>{{ $t('loan.fuwufei') }}</span>
        <span>PHP {{ getmoney(infoData.service_charge) }}</span>
      </div>
      <div class="loan-info" v-if="coupon && coupon.id">
        <span>{{ $t('cupones.withdrawTicket') }}</span>
        <span>PHP {{ getmoney(coupon.TUZLhbtv) }}</span>
      </div>
      <div class="loan-info">
        <span>{{ $t('loan.daozhangjie') }}</span>
        <span>PHP {{ getmoney(infoData.UkufumyaInkalata + (coupon && coupon.TUZLhbtv)) }}</span>
      </div>
      <div class="loan-agreement">
        <img v-if="isClickLoan" @click="isClickLoan = !isClickLoan"
          src="../../../assets/images/compoundLoan/select-active-icon.png" alt="">
        <img v-else @click="isClickLoan = !isClickLoan" src="../../../assets/images/compoundLoan/select-icon.png" alt="">
        <p>{{ $t('loan.woyiyuedutongyi') }}<span class="blue" @click="handleToLoanAgreement">
            {{ $t('loan.jiekuanxieyi') }}</span></p>
      </div>
      <submit-btn :submit-flag="true" :subText="$t('loan.queren')" @submit="submit()" />
    </div>
  </van-popup>
</template>

<script>
import { submitOrderGetDetail, submitOrder, reloanPoint } from "@/api/axios";
export default {
  props: {
    visbile: {
      type: Boolean,
      default: true
    },
    moneyNum: {
      type: [String | Number],
      default: ''
    },
    timers: {
      type: [String | Number],
      default: ''
    },
    coupon: {
      type: Object,
      default: {
        id: 0
      }
    }
  },
  data() {
    return {
      isClickLoan: true,
      infoData: {}
    }
  },
  created() {


  },
  watch: {
    visbile: {
      handler(val) {
        if (val) {
          this.getInfoData()
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    async getInfoData() {
      let dto = {
        ukufumyaIfingi: this.moneyNum,//借款金额
        LoanTerm: this.timers,//借款周期
        Ukufumya_icilefwaikwa_pafisa: '1',//首付贷 0：首贷 1：复贷
      }
      const res = await submitOrderGetDetail(dto)
      this.infoData = res.data
    },
    closeAction() {
      this.$emit('update:visbile', false)
      this.$emit('close')
    },
    // 跳转借款协议
    handleToLoanAgreement() {
      console.log(this.infoData, 'this.infoData');
      this.$router.push(
        {
          path: '/loanAgreementUse',
          query: {
            type: 'loan',
            cash_loan: this.infoData.ukufumyaIfingi,//金额
            day_loan: this.infoData.Ukulungisha_bwino,//天数
          }
        }
      )
      console.log(1123123);
    },
    async submit() {
      if (!this.isClickLoan) {
        this.$toast.fail(this.$t('loan.lookloanxieyi'))
        return
      }
      this.$store.state.settings.loanpoint.confirm_num += 1
      console.log(1111);
      let obj = {
        ukufumyaIfingi: this.moneyNum,//借款金额
        LoanTerm: this.timers,//借款周期
        withdraw_ticket_id: this.coupon && this.coupon.id
      }
      console.log(obj);
      const res = await submitOrder(obj)
      this.$emit('close')
      // await reloanPoint(this.$store.state.settings.loanpoint)
      this.$H5Object.homeclick()
      console.log(res);
    }

  }
}
</script>

<style lang="scss" scoped>
.detail-head {
  padding-top: 48px;
  padding-bottom: 32px;
  position: relative;

  img {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 62px;
    left: 40px;
  }

  p {
    text-align: center;
    height: 72px;
    font-size: 48px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #000000;
    line-height: 72px;
  }
}

.content-box {
  padding: 0 40px;

  .loan-info {
    display: flex;
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
    line-height: 44px;
    justify-content: space-between;
    margin-bottom: 24px;

    span:nth-child(2) {
      font-weight: 500;
      color: #000000;
    }
  }

  .plan-box {
    width: 100%;
    background: #F7F8FC;
    border-radius: 24px;
    padding: 32px 40px;
    margin-bottom: 24px;

    .plan-list {
      margin-top: 32px;

      .plan-item {
        position: relative;
        padding-left: 40px;

        .plan-item-top {
          font-size: 28px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #000000;
          line-height: 40px;

          span:nth-child(2) {
            padding: 0 8px;
            background: rgba(0, 95, 233, 0.1);
            border-radius: 8px;
            font-size: 24px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #005FE9;
            line-height: 36px;
            margin-left: 8px;
          }
        }

        .plan-item-bottom {
          margin-top: 16px;
          font-size: 28px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #B3B3B3;
          line-height: 40px;
        }

        .plan-item-data {
          font-size: 24px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #F5452B;
          line-height: 36px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .plan-item:before {
        /* 流程点的样式 */
        content: '';
        background: url('../../../assets/images/compoundLoan/plan-icon.png') no-repeat;
        background-size: 100%;
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 6px;
        left: 0;
      }

      .plan-first-item:after {
        content: '';
        width: 2px;
        height: 122px;
        background: #005FE9;
        position: absolute;
        top: 30px;
        left: 10px;
      }

      .plan-item:nth-child(2) {
        margin-top: 48px;
      }

      .plan-footer {
        display: flex;
        align-items: center;
        margin-top: 32px;

        img {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }

        p {
          width: 550px;
          font-size: 24px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #FF8500;
          line-height: 36px;
        }
      }
    }



  }

  .loan-agreement {
    display: flex;
    align-items: center;
    margin-top: 32px;

    img {
      width: 36px;
      height: 36px;
      margin-right: 16px;
    }

    p {
      width: 618px;
      font-size: 26px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #999999;
      line-height: 40px;

      .blue {
        color: #005FE9;
      }
    }
  }

  /deep/ .btn-box {
    margin-top: 24px !important;

    .can-submit-btn {
      background: #1C1D21 !important;
    }
  }
}

.van-popup--bottom.van-popup--round {
  border-radius: 48px 48px 0px 0px;
}
</style>