<template>
  <div class="loan">
    <template v-if="!isCupon">
      <div class="top-box-area">
        <div class="top-box-area-header">
          <span class="title">CashAgad</span>
          <span class="serivce" @click="openService"></span>
        </div>
      </div>
      <div class="main-container">
        <div class="money-box">
          <span class="title">{{ $t('loan.fudaijine') }}(PHP)</span>
          <ul class="product-list">
            <li v-for="(item, index) in sys_plan_list" :class="{
              selected: index == selectIndex,
              locked: !item.Agifyo_ndecita
            }" @click="productSelect(item, index)">
              <p> {{ getmoney(item.ukufumyaIfingi) }}</p>

            </li>
          </ul>
          <!-- 借款天数 -->
          <p class="loan-day"> {{ $t('loan.jiekuanriqi') }} <span class="dayclass">{{ dip_loanDay }} {{ $t('loan.day')
          }}</span>
          </p>
          <!-- 第一期借款天数 -->
          <p class="firstday">{{ $t('loan.firstloanday') }}</p>
          <ul class="loanday-ul">
            <li :class="{ 'active-li': index == loanDayIndex }" v-for="(item, index) in dayArr" :key="index"
              @click="handleLoanDay(item, index)">{{ item.tgio }} {{ $t('loan.day') }}</li>
          </ul>
        </div>
        <div class="coupon-box" @click="isCupon = true" v-if="dataList.length > 0">
          <div class="coupon-left">
            <img class="left-icon" src="@/assets/images/coupon/coupon-icon.png" alt="">
            <div v-if="cuponDto">
              <p class="coupon-name">{{ cuponDto.title }}</p>
              <p class="coupon-time">{{ $t('cupones.youxiaoqi') }}{{ cuponDto.IjjAhMsb }}</p>
            </div>
            <div v-else>
              <p class="coupon-name">{{ $t('cupones.weixuanzeyouhuiquan') }}</p>
            </div>
          </div>
          <div class="coupon-right">
            <span v-if="cuponDto">+{{ $t('public.unit1') }}{{ cuponDto.TUZLhbtv }}</span>
            <img class="right-icon" src="@/assets/images/coupon/coupon-right.png" alt="">
          </div>

        </div>


        <div class="content-box">
          <submit-btn :submit-flag="true" :subText="$t('loan.lijishenqing')" @submit="submit()" />
          <p class="text-style">{{ $t('loan.anshihuankuan') }}</p>
          <div class="msgs" v-if="dataInfo.Inyimboo">
            <van-notice-bar left-icon="volume-o" :text="dataInfo.Inyimboo" />
          </div>
          <Banner class="banner-box" :bannerList="bannerList" v-if="bannerList.length > 0" />
          <p class="footer-text">{{ $t('loan.loanTerm') }}</p>
        </div>
      </div>
    </template>


    <loanDetail :visbile.sync="visbile" :moneyNum="moneyNum" :timers="loanDay" @close="visbile = false"
      :coupon="cuponDto"></loanDetail>

    <cupon ref="cuponEl" :type="2" v-show="isCupon" @headerBack="headerBack" @change="handleCupon"
      @interface="handleInterface">
    </cupon>
    <!-- 运营后台送券弹窗 -->
    <van-popup v-model="popData.show" position="center" class="coupon-popup">
      <div class="coupon-container">
        <div class="top">
          <p class="popup-title">{{ popData.title }}</p>
          <p class="msg">{{ popData.con_ilyashi }}</p>

          <div class="coupon-content">
            <p class="coupon-amount-title"> {{ $t('cupones.couponDiscount') }}(peso)</p>
            <p class="coupon-amount">{{ getmoney(popData.ticket_amount) }}</p>
            <p class="expire-time">{{ $t('cupones.youxiaoqi') }}{{ popData.expire_date }}</p>
          </div>

          <p class="check" @click="checkCoupon">{{ $t('cupones.checkCoupon') }}>></p>
        </div>
        <div class="bottom">
          <p class="use-btn" @click="popData.show = false">{{ popData.btn_title }}</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>

import { getStayPayList } from '@/api/axios'
import Banner from '@/components/banner.vue'
import loanDetail from './components/loanDetail.vue'
import cupon from '../stayPay/cupon.vue'
export default {
  components: {
    Banner, loanDetail, cupon
  },
  data() {
    return {
      visbile: false,
      notification_msg: '',
      bannerList: [],
      selectIndex: '',//数组中最大金额的索引值
      loanDay: 0,//借款天数
      dip_loanDay: 0,//借款期限
      loanDayIndex: 0,//借款天数索引值
      sys_plan_list: [],
      dataInfo: {},
      cuponDto: null,//选中的优惠券
      dataList: [],
      isCupon: false,
      popData: {
        btn_title: "btn",
        con_ilyashi: "ssssssssssssssss",
        expire_date: "20-22-22",
        op_ticket_type: 1,
        show: false,
        ticket_amount: 1000,
        ticket_type: 1,
        title: "titleText"
      },//运营后台送券信息
    }
  },
  computed: {
    moneyNum() {
      let a = 0
      if (this.selectIndex !== '') {
        a = this.sys_plan_list[this.selectIndex] ? this.sys_plan_list[this.selectIndex].ukufumyaIfingi : 0
      }
      return a
    },
    dayArr() {
      let a = []
      if (this.selectIndex !== '') {
        a = this.sys_plan_list[this.selectIndex] ? this.sys_plan_list[this.selectIndex].Ukuftashi : []
      }
      return a
    }
  },
  watch: {
    selectIndex: {
      handler(val) {
        console.log(this.selectIndex !== '', 333, this.sys_plan_list, val, this.sys_plan_list[val]);
        if (val !== '') {
          console.log(val, 'val的覆盖广泛发生');
          let arr = this.sys_plan_list[val]?.Ukuftashi || []
          const arr1 = arr.map((item) => item.tgio);
          this.loanDay = Math.max.call(null, ...arr1);
          this.loanDayIndex = arr.findIndex(item => item.tgio == this.loanDay)
          this.dip_loanDay = arr[this.loanDayIndex].dip_tgio
        }
      },
      immediate: false,
      deep: true
    },
    visbile: {
      handler(val) {
        if (val) {
          this.$H5Object.isShowBottomTabinter(false)
        } else {
          this.$H5Object.isShowBottomTabinter(true)
        }
      },
      immediate: true
    }
  },
  created() {
    this.getStayPayList()

    this.$H5Object.changeStatusBarBgColor('#005FE9')
    this.$H5Object.changeStatusIsDarkFont(false)
    this.$H5Object.isShowBottomTabinter(true)
    window.eaH5PageBack = this.goback
    window.refreshPages = this.handlerRefreshPages
  },
  mounted() {

  },
  methods: {
    headerBack() {
      this.isCupon = false
    },
    // 选择的优惠券
    handleCupon(val, item) {
      this.cuponDto = item || null
      console.log(val, item, 'val,item');
    },
    // 收否有优惠券数据
    handleInterface(dataList) {
      this.dataList = dataList
    },
    // 打开客服
    openService() {
      this.$H5Object.goCustomServicePage()
    },
    // 查看个人中心优惠券
    checkCoupon() {
      this.$router.push({
        name: 'Cupones',
        query: {
          from: "compoundLoan"
        }
      })
      this.$H5Object.isShowBottomTabinter(false)
    },
    // 刷新页面
    handlerRefreshPages() {
      this.getStayPayList()
    },

    goback() {
      if (this.visbile) {
        this.visbile = false
      } else {
        this.$H5Object.cancleView()
      }

    },
    // 获取最大值的下标
    getMaxVal(arr, field) {
      const arr1 = arr.map((item) => item[field]);
      const max = Math.max.call(null, ...arr1);
      return arr.findIndex(item => item[field] == max)
    },
    // 点击加减
    handleMoney(type) {
      if (type == 'reduce') {
        this.selectIndex ? this.selectIndex-- : 0
      }
      if (type == 'plus') {
        this.selectIndex != this.sys_plan_list.length - 1 ? this.selectIndex++ : this.sys_plan_list.length - 1
      }
      this.$store.state.settings.loanpoint.money_updatecount += 1
      this.$store.state.settings.loanpoint.money_num += 1
      console.log(this.selectIndex, this.sys_plan_list[this.selectIndex])
    },

    //
    productSelect(item, index) {
      if (!item.Agifyo_ndecita) {
        this.$toast(this.$t('stayPay.upAmount'))
        return
      }
      this.selectIndex = index
      this.$store.state.settings.loanpoint.money_updatecount += 1
      this.$store.state.settings.loanpoint.money_num += 1
    },
    // 点击借贷天数
    handleLoanDay(item, index) {
      this.loanDay = item.tgio
      this.dip_loanDay = item.dip_tgio
      this.loanDayIndex = index
      this.$store.state.settings.loanpoint.term_updatecount += 1
      this.$store.state.settings.loanpoint.term_num += 1
    },
    // 获取首页数据
    async getStayPayList() {
      let dto = {
        // UkucinjaKwakulekelesha:this.isAllPay?1:2
        need_home_pop_data: 1
      }

      const res = await getStayPayList(dto)

      this.dataInfo = res.data
      this.sys_plan_list = res.data?.Ukutantika_bwangu || []
      if(res.data&&res.data.pop_data){
        this.popData = res.data?.pop_data //运营后台送券
      }
      console.log(this.sys_plan_list, '11112323ssss');
      this.bannerList = res.data?.amaBanner || []
      this.bannerList.forEach((item) => {
        item.url = item.amaBanner_url
        // let dto = {
        //   url: item.amaBanner_url,
        // }
        // item = { ...item, ...dto }
      })
      //选中最大可解金额
      this.selectIndex = this.sys_plan_list.findIndex((item, index, arr) => {
        return item.Agifyo_ndecita && !arr[index + 1].Agifyo_ndecita
      })

      // this.$nextTick(() => {
      //   this.selectIndex = this.getMaxVal(this.sys_plan_list, 'ukufumyaIfingi')

      // })
    },
    // 提交
    submit() {
      this.visbile = true
      this.$store.state.settings.loanpoint.apply_updatecount += 1
      this.$store.state.settings.loanpoint.apply_num += 1
    }

  }
}
</script>
<style lang="scss" scoped>
.app-content {
  background: #fff !important;
}

/deep/ .van-popup--bottom {
  left: 0 !important;
}

.loan {
  overflow: hidden;

  .top-box-area {
    height: 500px;
    padding: 22px 40px;
    margin-top: 88px;
    background: url('~@/assets/images/compoundLoan/top-bg-icon.png') no-repeat right top #005FE9FF;
    background-size: 482px 380px;

    .top-box-area-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 88px;
      background: #005FE9;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      z-index: 20;

      .title {
        font-size: 56px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 84px;
      }

      .serivce {
        display: block;
        width: 44px;
        height: 44px;
        background: url('~@/assets/images/public/serivce-icon.png') no-repeat center center;
        background-size: 100%;
      }
    }
  }

  .main-container {
    padding: 0 40px;
    position: relative;
    top: -216px;
  }

  .money-box {
    padding: 64px 48px 48px;
    background: #1C1D21 url('~@/assets/images/compoundLoan/main-bg.png') no-repeat top;
    background-size: 100% 558px;
    border-radius: 32px;
    min-height: 558px;
    margin-bottom: 24px;

    .title {
      display: block;
      font-size: 28px;
      font-weight: 400;
      color: #CCCCCC;
      margin-bottom: 32px;


    }

    .product-list {
      display: flex;
      flex-wrap: wrap;

      li {
        width: calc(33% - 16px);
        height: 76px;
        background: #ffffff;
        border-radius: 16px;
        margin-bottom: 24px;
        margin-right: 24px;

        &:nth-of-type(3n) {
          margin-right: 0;
        }

        p {
          font-size: 28px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #1C1D21;
          line-height: 76px;
          text-align: center;
        }
      }

      .selected {
        background: #F7B014;

        p {
          color: #fff;
        }
      }

      .locked {
        background: #666;

        p {
          color: #fff;
        }
      }

    }

    .loan-day {
      font-size: 28px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #CCCCCC;
      line-height: 36px;
      margin-top: 24px;
    }

    :deep(.dayclass) {
      font-size: 28px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px;
      margin-left: 16px;
    }

    .firstday {
      font-size: 28px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #CCCCCC;
      line-height: 36px;
      margin-top: 32px;
    }

    .loanday-ul::-webkit-scrollbar {
      display: none
    }

    .loanday-ul {
      display: flex;
      flex-wrap: wrap;
      height: 80px;
      overflow-y: auto;
      margin-top: 30px;

      li {
        width: 160px;
        height: 76px;
        background: #666;
        border-radius: 16px;
        font-size: 28px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 76px;
        text-align: center;
        margin-right: 24px;

      }

      li:nth-child(3*n) {
        margin-right: 0px;
      }

      .active-li {
        background: #F7B014;
      }
    }

  }

  .content-box {
    width: 670px;
    margin: 24px auto;
    margin-bottom: 250px;

    .text-style {
      margin-top: 24px;
      padding: 0 48px;
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #B3B3B3;
      line-height: 36px;
    }

    .msgs {
      margin-top: 40px;

      :deep(.van-notice-bar) {
        height: 124px;
        background: #F4F5FC;
        border-radius: 32px;
        padding: 0 40px;

        .van-notice-bar__left-icon {
          width: 60px;

          &::before {
            display: none;
          }

          &::after {
            content: '';
            position: absolute;
            width: 44px;
            height: 44px;
            left: 0;
            top: 50%;
            margin-top: -22px;
            background: url('~@/assets/images/public/notice-icon.png') no-repeat center center;
            background-size: 100%;
          }
        }

        .van-notice-bar__content {
          font-size: 28px;
          font-weight: 400;
          color: #1C1D21;
        }
      }
    }

    .banner-box {
      margin-top: 40px;
    }

    .footer-text {
      margin: 40px auto;
      width: 538px;
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #B3B3B3;
      line-height: 36px;

    }
  }

  :deep(.btn-box) {


    .submit-btn.can-submit-btn {
      background: #005FE9;
      color: #FFFFFF;
    }
  }

}

.coupon-box {
  min-height: 124px;
  padding: 18px 32px;
  background: #F4F5FC;
  border-radius: 32px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .coupon-left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-icon {
      width: 44px;
      height: 44px;
      margin-right: 14px;
    }

    .coupon-name {
      font-size: 28px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #1C1D21;
      line-height: 44px;
      width: 380px;
    }

    .coupon-time {
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #999999;
      line-height: 44px;
    }
  }

  .coupon-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 28px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #005FE9;
      line-height: 44px;
    }

    img {
      width: 48px;
      height: 48px;
    }
  }
}

.coupon-popup {
  width: 670px;
  background: transparent;

  .coupon-container {
    font-size: 0;

    .top {
      padding: 64px 56px 48px;
      background: #fff;

      .popup-title {
        font-size: 48px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #333333;
        line-height: 56px;
        text-align: center;
      }

      .msg {
        font-size: 24px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #4d4c4c;
        line-height: 56px;
        text-align: center;
      }

      .coupon-content {
        width: 100%;
        height: 234px;
        background: url('../../assets//images//stayPay/popup-coupon-bg.png') no-repeat;
        background-size: 100% 100%;
        padding: 24px 30px;
        margin-top: 48px;

        .coupon-amount-title {
          font-size: 32px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 38px;
        }

        .coupon-amount {
          margin-top: 12px;
          font-size: 72px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 88px;
        }

        .expire-time {
          margin-top: 16px;
          font-size: 28px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.8);
          line-height: 32px;
        }
      }

      .check {
        margin-top: 30px;
        text-align: center;
        font-size: 28px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #005FE9;
        line-height: 32px;
      }
    }

    .bottom {
      width: 100%;
      height: 238px;
      background: url('../../assets/images/stayPay/popup-show-bottom.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -2px;
      padding-top: 94px;

      .use-btn {
        margin: 0 auto;
        width: 542px;
        height: 96px;
        background: #1C1D21;
        border-radius: 32px;
        font-size: 36px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 96px;
        text-align: center;
      }
    }
  }
}
</style>
