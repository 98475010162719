<template>
  <div>

    <div class="cupon-item-box" :class="{ blue: type == 1, red: type == 2, grey: type == 3 }">
      <p class="title">{{ title }}</p>
      <p class="money">{{ $t('public.unit') }} {{ Math.ceil( money)| toThousandFilter }}</p>
      <p class="time">{{$t('cupones.youxiaoqi')}}{{ time }}</p>
      <span v-if=" type == 3" class="expired-style">{{ $t('cupones.yishixiao') }}</span>
      <span v-if=" type == 2" class="expired-style">{{ $t('cupones.yishiyong') }}</span>
      <!-- <div v-if="isSelect" class="check-btn">
        <img src="@/assets/images/coupon/select.png" alt="">
        <img src="@/assets/images/coupon/no-select.png" alt="">
      </div> -->
      <van-radio v-if="isSelect" class="check-riado" :name="id">
        <template #icon="props">
          <span :class="{ 'is-check': props.checked }" class="check-icon" />
        </template>
      </van-radio>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 优惠券说明title
    title: {
      type: String,
      default: ''
    },
    // 优惠券金额
    money: {
      type: [Number, String],
      default: ''
    },
    // 优惠券时间
    time: {
      type: String,
      default: ''
    },
    // 类型
    type: {
      type: [Number, String],
      default: ''
    },
    // 是否可以选择
    isSelect: {
      type: [Boolean],
      default: false
    },
    id: {
      type: [Number, String],
      default: ''
    }

  },
  data() {
    return {
      radio: 1
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.cupon-item-box {
  height: 272px;
  margin-bottom: 32px;
  padding: 32px 40px;
  position: relative;
.expired-style{
  padding:14px 28px;
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #999999;
  position: absolute;
  top: 32px;
  right: 32px;
  border-radius: 8px;
  background: #E0E0E0;

}
  .title {
    font-size: 28px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 40px;
    width: 400px;
  }

  .money {
    font-size: 64px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 84px;
    margin-top: 20px;
  }

  .time {
    font-size: 24px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 44px;
    margin-top: 20px;
  }

  .check-riado {
    position: absolute;
    top: 50%;
    right: 40px;
    margin-top: -20px;
  }

  /deep/ .van-radio__icon {
    width: 48px;
    height: 48px;
  }

  .check-icon {
    display: block;
    width: 48px;
    height: 48px;
    background: url('~@/assets/images/coupon/no-select.png') no-repeat center center;
    background-size: 100%
  }

  .is-check {
    background: url('~@/assets/images/coupon/select.png') no-repeat center center;
    background-size: 100%
  }
}

.blue {
  background: url('~@/assets/images/coupon/cupon-bg.png') no-repeat;
  background-size: 100% 100%;
}

.red {
  background: url('~@/assets/images/coupon/overdue-bg.png') no-repeat;
  background-size: 100% 100%;
}

.grey {
  background: url('~@/assets/images/coupon/use-bg.png') no-repeat;
  background-size: 100% 100%;

  .title,
  .money,
  .time {
    color: #999999;
  }
}</style>