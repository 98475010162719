<template>
  <div class="repa-banner">
    <van-swipe v-if="bannerList.length>1" class="my-swipe" :autoplay="5000" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index">
        <img class="banner-box-img" :src="item.url" alt="" @click="goweblink(item)" />
      </van-swipe-item>
    </van-swipe>
    <img v-else class="banner-box-img" v-for="(item, index) in bannerList" :key="index" :src="item.url" alt="" @click="goweblink(item)" />
  </div>
</template>

<script >
export default {
  name: 'banner',
  props: {
    bannerList: {
      type: [Object, Array],
      default: []
    }
  },

  created() { },
  methods: {
    goweblink(data) {
      // this.$H5Object.g54dlfe1p0(data)
    }
  }

}
</script>
<style lang='scss' scoped>
.bannerwrap {
  border-radius: 30px;
  overflow: hidden;
}

.repa-banner {
  height: 124px;
  width: 100%;
  position: relative;
}

.banner-box-img {
  height: 100%;
  width: 100%;
}

.banner-box-content {
  position: absolute;
  top: 28px;
  left: 200px;
}

.banner-box-content-title {
  width: 350px;
  height: 67px;
  font-size: 48px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffca08;
  line-height: 67px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.banner-box-content-text {
  width: 456px;
  height: 37px;
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffca08;
  line-height: 37px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/deep/ .van-swipe-item {
  border-radius: 28px !important;
  overflow: hidden;
}
</style>